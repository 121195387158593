<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-start align-items-left">
        <a @click="goToIndemnification()">
          <feather-icon icon="ChevronLeftIcon" size="24" />
        </a>
        <div>
          <h4 class="mb-0">Historial de ajustes</h4>
        </div>
      </div>
     <form-render :form.sync="form" :key="keyFormRender" :fields="fields" @send="filterList" ref="formFilter" containerButtonsClass="mt-2 ml-1 flex-md-grow-1" class="mb-2 mt-2" :buttonSend="buttonSend">
      <template #buttons>
          <b-button
            variant="outline-light"
            v-b-tooltip.hover
            title="Limpiar filtros"
            class="ml-1"
            @click="cleanFilter"
            ><feather-icon icon="RefreshCwIcon"
          /></b-button>
          <div class="d-inline float-md-right" v-b-tooltip.hover :title="$t('Seleccione rango de fecha')">
            <b-button class="ml-2 ml-md-0 mr-md-3" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" :disabled="isDisabledExport || loading.download" @click="downloadReport()">
              <b-spinner v-show="loading.download" small class="mr-1"></b-spinner>
              <span v-show="!loading.download">
                <feather-icon icon="FileIcon" /> Reporte
              </span>
            </b-button>
          </div>
      </template>
      </form-render>
      <table-render
        v-if="!loading.first"
        id="table_indemnificationsList"
        class="ml-10 mr-10"
        :rows="rows"
        :schema="schema"
        :loading="loading.indemnifications"
        :actions="actions"
      >
      </table-render>
      <pagination
        v-if="!loading.first"
        :pagination="pagination"
        :noDigits="false"
        :showSize="true"
        :disabled="loading.indemnifications"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
        :rows="pagination.limit || 10"
        :columns="schema.length || 10"
        :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['company_id'],
  data() {
    return {
      loading: {
        first: true,
        indemnifications: true,
        download: false
      },
      fields: [],
      form: {},
      keyFormRender: 0,
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      rows: [],
      schema: [],
      actions: [],
      pagination: { page: 1, limit: 10, total: 0, loading: false },
      filterCount: 0,
      isDisabledExport: true
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      historyAjust: 'getHistoryAjust'
    })
  },
  watch: {
    mySession () {
      this.setInitialData()
    },
    filterCount() {
      this.isDisabledExport = this.filterCount === 0 // If there are no filters, the export button is disabled
    },
    historyAjust() {
      this.rows = this.historyAjust.rows.map(history => {
        return {
          ...history,
          reason: history.reason,
          amount: this.$options.filters.currency(history.amount, 0, '$'),
          amountAdjustment: this.$options.filters.currency(history.amountAdjustment, 0, '$'),
          user: history.indemnification.loggedUserEmail,
          trackingNr:  history.delivery.trackingNr,
          carrier_name: history.delivery?.carrier?.name || null,
          correlationNumber: history.indemnification.correlationNumber,
          date : this.$options.filters.UTCDateFormatter(history.indemnification.date)
        }
      })
      this.loading.first = false
      this.loading.indemnifications = false
      this.pagination.page = this.historyAjust.pagination.current_page
      this.pagination.total = this.historyAjust.pagination.total_items
      this['pagination.limit'] = this.historyAjust.pagination.total_pages
    },
    'pagination.page'() {
      this.getHistoryAjust()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getHistoryAjust()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.getHistoryAjust()
      this.schema = [
        { label: 'Número', key: 'correlationNumber' },
        { label: 'Courier', key: 'carrier_name', doubleDash: true },
        { label: 'OT', key: 'trackingNr', doubleDash: true },
        { label: 'Monto', key: 'amount', doubleDash: true},
        { label: 'Ajuste', key: 'amountAdjustment', doubleDash: true},
        { label: 'Motivo', key: 'reason', doubleDash: true},
        { label: 'Fecha', key: 'date', doubleDash: true },
        { label: 'Usuario', key: 'user', doubleDash: true }
      ]
      this.fields = [
        { fieldType: 'FieldDatepicker', label: this.$t('Fecha'), name: 'date', placeholder: 'Seleccione rango de fecha', clearable: true, containerClass: 'container-info col-md-4', range: true },
        { fieldType: 'FieldInput', name: 'ot_string', label: 'OT', containerClass: 'col-sm-12 container-info col-md-3', placeholder : 'Ingrese OT' }
      ]

    },
    goToIndemnification() {
      this.$router.replace({ name: 'billing-details', params: { id: this.$route.params.id } })
      window.location.reload()
    },
    getHistoryAjust(payloadFilter = {}) {
      this.loading.indemnifications = true
      const queryParams = {
        page: this.pagination.page,
        limit: this.pagination.limit,
        ...payloadFilter
      }
      const params = {
        company_id: this.$route.params.id
      }
      this.$store.dispatch('fetchService', {
        name: 'getHistoryAjust',
        params,
        queryParams
      })
    },
    filterList(form) {
      const payloadFilter = {
        ...this.generateFilters(form)
      }
      this.pagination.page = 1
      this.filterCount++
      this.getHistoryAjust(payloadFilter)
    },
    generateFilters(form) {
      const filters = {}
      if (form.date) {
        filters['filter[dateSince]'] = this.$options.filters.moment(form.date.start, 'YYYY-MM-DD') || ''
        filters['filter[dateUntil]'] = this.$options.filters.moment(form.date.end, 'YYYY-MM-DD') || ''
      }
      if (form.ot_string) filters['filter[ot]'] = form.ot_string
      return filters
    },

    cleanFilter() {
      this.form = {}
      this.keyFormRender++
      this.filterCount = 0
      this.filterList(this.form)
    },
    downloadReport() {
      const params = {
        company_id: this.$route.params.id
      }
      const queryParams = {
        ...this.generateFilters(this.form)
      }
      this.loading.download = true
      this.$store.dispatch('fetchServiceGenerateExcel', {
        name: 'getHistoryAjustReport',
        params,
        fileName: 'Historial-de-ajustes',
        queryParams,
        onSuccess: (response) => {
          this.loading.download = false
          this.$success(this.$t('msg-exito-descarga'))
        },
        onError: () => {
          this.loading.download = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
